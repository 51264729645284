import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const List = styled.ul`
  & a {
    text-decoration: none;
  }
`;

export default ({ docs }) => (
  <List>
    {docs.map(({ node: { fields: { slug }, frontmatter: { title } } }) => (
      <li key={slug}>
        <p>
          <Link to={slug}>{title}</Link>
        </p>
      </li>
    ))}
  </List>
);

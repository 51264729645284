import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

import { color } from '../theme';
import Logo from '../icons/Logo.jsx';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2000;

  padding-left: 20px;
  padding-right: 20px;

  height: 45px;
  min-height: 45px;
  line-height: 45px;

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: ${color(3)};
  color: white;
  fill: white;

  & > * {
    flex: 0 0 auto;
  }

  @media print {
    display: none;
  }
`;

const LargeHeaderWrapper = styled(HeaderWrapper)`
  height: 65px;
  min-height: 65px;
  line-height: 65px;
`;

const Title = styled.a`
  user-select: none;
  display: flex;
  margin-right: auto;

  text-decoration: none;

  color: inherit;
  fill: inherit;

  font-size: large;

  height: 45px;

  & > * {
    flex: 0 0 auto;
  }

  & > :not(:first-child) {
    margin-left: 20px;
  }
`;

const LargeTitle = styled(Title)`
  height: 65px;
  font-size: larger;
`;

const NavWrapper = styled.div`
  margin-right: 20px;
`;

export const NavigationElement = styled(Link)`
  flex: 0 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;

  user-select: none;
  white-space: nowrap;

  color: inherit;
  text-decoration: none;
  font-weight: lighter;

  ${props =>
    props.selected
      ? `
  font-weight: bold;
  cursor: default;
  `
      : ''};

  :not(:last-child) {
    padding-right: 20px;
  }
`;

const Header = ({ lang }) => (
  <>
    <LargeHeaderWrapper>
      <LargeTitle target="_blank" rel="noopener noreferrer" href="https://app.horair.es">
        <Logo width="40px" />
        <span>Horair.es</span>
      </LargeTitle>

      <NavWrapper>
        {lang === 'en' && <NavigationElement to="/en/docs">Docs</NavigationElement>}
        {lang === 'fr' && <NavigationElement to="/fr/docs">Docs</NavigationElement>}

        <NavigationElement to="/en" selected={lang === 'en'}>
          en
        </NavigationElement>
        <NavigationElement to="/fr" selected={lang === 'fr'}>
          fr
        </NavigationElement>
      </NavWrapper>
    </LargeHeaderWrapper>
  </>
);

export const DocHeader = ({ lang }) => (
  <>
    <HeaderWrapper height="45px">
      <Title as={Link} to={`/${lang}`} height="45px">
        <Logo width="30px" />
        <span>Horair.es</span>
      </Title>
      <NavWrapper>
        {lang === 'en' && <NavigationElement to="/en/docs">Docs</NavigationElement>}
        {lang === 'fr' && <NavigationElement to="/fr/docs">Docs</NavigationElement>}
      </NavWrapper>
    </HeaderWrapper>
  </>
);

export default Header;
